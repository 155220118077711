
import state from "./moduleUnitFinancialPositionStatusState.js"
import mutations from "./moduleUnitFinancialPositionStatusMutations.js"
import getters from "./moduleUnitFinancialPositionStatusGetters.js"
import actions from './moduleUnitFinancialPositionStatusActions.js';
export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
