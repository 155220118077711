export default {
  ADD_ITEM(state, item) {
    state.FinancialPositionStatus.unshift(item);
  },
  SET_FinancialPositionStatus(state, FinancialPositionStatus) {
    state.FinancialPositionStatus = FinancialPositionStatus;
  },
  UPDATE_FinancialPositionStatus(state, FinancialPositionStatus) {
    const FinancialPositionStatusIndex = state.FinancialPositionStatus.findIndex(p => p.ID == FinancialPositionStatus.ID);
    if (FinancialPositionStatusIndex != -1) {
      Object.assign(state.FinancialPositionStatus[FinancialPositionStatusIndex], FinancialPositionStatus);
    }
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.FinancialPositionStatus.findIndex(p => p.ID == itemId);
    state.FinancialPositionStatus.splice(ItemIndex, 1);
  }
};

